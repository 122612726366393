import validate from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  aanvragen: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/aanvragen.ts"),
  auth: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/auth.ts"),
  avborrower: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/avborrower.ts"),
  avinvestor: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/avinvestor.ts"),
  avinvestorborrower: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/avinvestorborrower.ts"),
  company: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/company.ts"),
  currentfund: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/currentfund.ts"),
  fund: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/fund.ts"),
  iban: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/iban.ts"),
  investor: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/investor.ts"),
  mobile: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/mobile.ts"),
  naw: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/naw.ts"),
  onfido: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/onfido.ts"),
  overzicht: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/overzicht.ts"),
  partnerplan: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/partnerplan.ts"),
  qrcode: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/qrcode.ts"),
  sign: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/middleware/sign.ts")
}