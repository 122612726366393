import { default as _91choice_93Bv1oN8D2t2Meta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue?macro=true";
import { default as index9Q3bztk2OWMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/aanbod/index.vue?macro=true";
import { default as indexESVSJc6m1GMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/claims-en-opties/index.vue?macro=true";
import { default as _91hash_93ToZZSksNAKMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/einde-looptijd-lening/[hash].vue?macro=true";
import { default as indexxEObFVuCElMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/financieel-overzicht/financien/index.vue?macro=true";
import { default as index8pHVPd7AMHMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/financieel-overzicht/index.vue?macro=true";
import { default as indexlHIJ3Mwgl9Meta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/financieel-overzicht/rente/index.vue?macro=true";
import { default as indexOOmMKeMocHMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue?macro=true";
import { default as indexAVfks0Yf3qMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/hypotheek-overzicht/index.vue?macro=true";
import { default as index4R6mNKlvtkMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/hypotheken/[id]/index.vue?macro=true";
import { default as indexVtQOSUkKoqMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/hypotheken/index.vue?macro=true";
import { default as indexVogVoH8kPpMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/index.vue?macro=true";
import { default as index4F6yM5W2ScMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/instellingen/index.vue?macro=true";
import { default as index8H0S5So4zEMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/investeringen/[id]/index.vue?macro=true";
import { default as indexTyPDK3eN6sMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/investeringen/index.vue?macro=true";
import { default as indexyZkKLSiIedMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/login/index.vue?macro=true";
import { default as reset_45wachtwoord6OUJRYfp8ZMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/login/reset-wachtwoord.vue?macro=true";
import { default as wachtwoord_45vergetenbpNTY71agVMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/login/wachtwoord-vergeten.vue?macro=true";
import { default as indexr3iG23Vbt8Meta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue?macro=true";
import { default as indexCb5OX093TAMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue?macro=true";
import { default as indexjak0tm7000Meta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-aanvragen/index.vue?macro=true";
import { default as indexUA4NNRlV10Meta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue?macro=true";
import { default as indexWtqHWMObyOMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue?macro=true";
import { default as indexPDkezw0smpMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-organisaties/index.vue?macro=true";
import { default as indexECiILnXeSdMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue?macro=true";
import { default as indexW9q59FYmREMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-partnerplan/index.vue?macro=true";
import { default as indexEHcuYBTdCKMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue?macro=true";
import { default as indexj5xadAnqCmMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue?macro=true";
import { default as indexe9noxlmL8SMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-profiel/index.vue?macro=true";
import { default as indexiKHnIV4QeNMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue?macro=true";
import { default as indexlRQPcayrasMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue?macro=true";
import { default as indexno17tGw3orMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue?macro=true";
import { default as index3IzTQSOJJrMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/index.vue?macro=true";
import { default as indexfq8jfJB4ZbMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue?macro=true";
import { default as indexCKK9dCoPYRMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue?macro=true";
import { default as indexnHFp37evdJMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue?macro=true";
import { default as indexBUQBxLD4L8Meta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/index.vue?macro=true";
import { default as errorSQzNblMTkoMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/ockto/[hash]/error.vue?macro=true";
import { default as indexO2hvbG81GHMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/ockto/[hash]/index.vue?macro=true";
import { default as qrcodeqNmLaoBLa3Meta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/ockto/[hash]/qrcode.vue?macro=true";
import { default as succes6ogie0VjLXMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/ockto/[hash]/succes.vue?macro=true";
import { default as _91token_93nkuM9iwfBPMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/onboarding/[signing_id]/[token].vue?macro=true";
import { default as _91slug_93B6iUIK5QxgMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/onboarding/[slug].vue?macro=true";
import { default as aanvraagPipNRKuXhxMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/onboarding/aanvraag.vue?macro=true";
import { default as _91experience_93PFvEN1m5msMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/onboarding/bedankt/[experience].vue?macro=true";
import { default as _91token_9386KSUsEyDAMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/onfido/[token].vue?macro=true";
import { default as _91hash_93qj8enUFlY4Meta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/passeren/[hash].vue?macro=true";
import { default as indexIqCEmI6DNhMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/privacy-verklaring/index.vue?macro=true";
import { default as _91hash_936jhiMqqTXQMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/rentevoorstel/[hash].vue?macro=true";
import { default as _91fundKey_93C5ZSHJeP4wMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue?macro=true";
import { default as aanvraagpAZijb82H5Meta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue?macro=true";
import { default as index3hUigKM9cdMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue?macro=true";
import { default as _91id_931aL2N7hmMuMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/sso/[token]/[id].vue?macro=true";
import { default as indexH8F6VcmkETMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/sso/[token]/index.vue?macro=true";
import { default as _91hash_93iKbK9Y7o2WMeta } from "/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/verzekeringen/[hash].vue?macro=true";
export default [
  {
    name: _91choice_93Bv1oN8D2t2Meta?.name ?? "aanbod-optie-propertyId-hash-choice",
    path: _91choice_93Bv1oN8D2t2Meta?.path ?? "/aanbod-optie/:propertyId()/:hash()/:choice()",
    meta: _91choice_93Bv1oN8D2t2Meta || {},
    alias: _91choice_93Bv1oN8D2t2Meta?.alias || [],
    redirect: _91choice_93Bv1oN8D2t2Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/aanbod-optie/[propertyId]/[hash]/[choice].vue").then(m => m.default || m)
  },
  {
    name: index9Q3bztk2OWMeta?.name ?? "aanbod",
    path: index9Q3bztk2OWMeta?.path ?? "/aanbod",
    meta: index9Q3bztk2OWMeta || {},
    alias: index9Q3bztk2OWMeta?.alias || [],
    redirect: index9Q3bztk2OWMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/aanbod/index.vue").then(m => m.default || m)
  },
  {
    name: indexESVSJc6m1GMeta?.name ?? "claims-en-opties",
    path: indexESVSJc6m1GMeta?.path ?? "/claims-en-opties",
    meta: indexESVSJc6m1GMeta || {},
    alias: indexESVSJc6m1GMeta?.alias || [],
    redirect: indexESVSJc6m1GMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/claims-en-opties/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93ToZZSksNAKMeta?.name ?? "einde-looptijd-lening-hash",
    path: _91hash_93ToZZSksNAKMeta?.path ?? "/einde-looptijd-lening/:hash()",
    meta: _91hash_93ToZZSksNAKMeta || {},
    alias: _91hash_93ToZZSksNAKMeta?.alias || [],
    redirect: _91hash_93ToZZSksNAKMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/einde-looptijd-lening/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexxEObFVuCElMeta?.name ?? "financieel-overzicht-financien",
    path: indexxEObFVuCElMeta?.path ?? "/financieel-overzicht/financien",
    meta: indexxEObFVuCElMeta || {},
    alias: indexxEObFVuCElMeta?.alias || [],
    redirect: indexxEObFVuCElMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/financieel-overzicht/financien/index.vue").then(m => m.default || m)
  },
  {
    name: index8pHVPd7AMHMeta?.name ?? "financieel-overzicht",
    path: index8pHVPd7AMHMeta?.path ?? "/financieel-overzicht",
    meta: index8pHVPd7AMHMeta || {},
    alias: index8pHVPd7AMHMeta?.alias || [],
    redirect: index8pHVPd7AMHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/financieel-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexlHIJ3Mwgl9Meta?.name ?? "financieel-overzicht-rente",
    path: indexlHIJ3Mwgl9Meta?.path ?? "/financieel-overzicht/rente",
    meta: indexlHIJ3Mwgl9Meta || {},
    alias: indexlHIJ3Mwgl9Meta?.alias || [],
    redirect: indexlHIJ3Mwgl9Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/financieel-overzicht/rente/index.vue").then(m => m.default || m)
  },
  {
    name: indexOOmMKeMocHMeta?.name ?? "fonds-optie-property_id-hash-type",
    path: indexOOmMKeMocHMeta?.path ?? "/fonds-optie/:property_id()/:hash()/:type()",
    meta: indexOOmMKeMocHMeta || {},
    alias: indexOOmMKeMocHMeta?.alias || [],
    redirect: indexOOmMKeMocHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/fonds-optie/[property_id]/[hash]/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAVfks0Yf3qMeta?.name ?? "hypotheek-overzicht",
    path: indexAVfks0Yf3qMeta?.path ?? "/hypotheek-overzicht",
    meta: indexAVfks0Yf3qMeta || {},
    alias: indexAVfks0Yf3qMeta?.alias || [],
    redirect: indexAVfks0Yf3qMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/hypotheek-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: index4R6mNKlvtkMeta?.name ?? "hypotheken-id",
    path: index4R6mNKlvtkMeta?.path ?? "/hypotheken/:id()",
    meta: index4R6mNKlvtkMeta || {},
    alias: index4R6mNKlvtkMeta?.alias || [],
    redirect: index4R6mNKlvtkMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/hypotheken/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVtQOSUkKoqMeta?.name ?? "hypotheken",
    path: indexVtQOSUkKoqMeta?.path ?? "/hypotheken",
    meta: indexVtQOSUkKoqMeta || {},
    alias: indexVtQOSUkKoqMeta?.alias || [],
    redirect: indexVtQOSUkKoqMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/hypotheken/index.vue").then(m => m.default || m)
  },
  {
    name: indexVogVoH8kPpMeta?.name ?? "index",
    path: indexVogVoH8kPpMeta?.path ?? "/",
    meta: indexVogVoH8kPpMeta || {},
    alias: indexVogVoH8kPpMeta?.alias || [],
    redirect: indexVogVoH8kPpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index4F6yM5W2ScMeta?.name ?? "instellingen",
    path: index4F6yM5W2ScMeta?.path ?? "/instellingen",
    meta: index4F6yM5W2ScMeta || {},
    alias: index4F6yM5W2ScMeta?.alias || [],
    redirect: index4F6yM5W2ScMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/instellingen/index.vue").then(m => m.default || m)
  },
  {
    name: index8H0S5So4zEMeta?.name ?? "investeringen-id",
    path: index8H0S5So4zEMeta?.path ?? "/investeringen/:id()",
    meta: index8H0S5So4zEMeta || {},
    alias: index8H0S5So4zEMeta?.alias || [],
    redirect: index8H0S5So4zEMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/investeringen/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTyPDK3eN6sMeta?.name ?? "investeringen",
    path: indexTyPDK3eN6sMeta?.path ?? "/investeringen",
    meta: indexTyPDK3eN6sMeta || {},
    alias: indexTyPDK3eN6sMeta?.alias || [],
    redirect: indexTyPDK3eN6sMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/investeringen/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZkKLSiIedMeta?.name ?? "login",
    path: indexyZkKLSiIedMeta?.path ?? "/login",
    meta: indexyZkKLSiIedMeta || {},
    alias: indexyZkKLSiIedMeta?.alias || [],
    redirect: indexyZkKLSiIedMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45wachtwoord6OUJRYfp8ZMeta?.name ?? "login-reset-wachtwoord",
    path: reset_45wachtwoord6OUJRYfp8ZMeta?.path ?? "/login/reset-wachtwoord",
    meta: reset_45wachtwoord6OUJRYfp8ZMeta || {},
    alias: reset_45wachtwoord6OUJRYfp8ZMeta?.alias || [],
    redirect: reset_45wachtwoord6OUJRYfp8ZMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/login/reset-wachtwoord.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetenbpNTY71agVMeta?.name ?? "login-wachtwoord-vergeten",
    path: wachtwoord_45vergetenbpNTY71agVMeta?.path ?? "/login/wachtwoord-vergeten",
    meta: wachtwoord_45vergetenbpNTY71agVMeta || {},
    alias: wachtwoord_45vergetenbpNTY71agVMeta?.alias || [],
    redirect: wachtwoord_45vergetenbpNTY71agVMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/login/wachtwoord-vergeten.vue").then(m => m.default || m)
  },
  {
    name: indexr3iG23Vbt8Meta?.name ?? "mijn-aanvragen-offerNumber-herkomst",
    path: indexr3iG23Vbt8Meta?.path ?? "/mijn-aanvragen/:offerNumber()/herkomst",
    meta: indexr3iG23Vbt8Meta || {},
    alias: indexr3iG23Vbt8Meta?.alias || [],
    redirect: indexr3iG23Vbt8Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-aanvragen/[offerNumber]/herkomst/index.vue").then(m => m.default || m)
  },
  {
    name: indexCb5OX093TAMeta?.name ?? "mijn-aanvragen-offerNumber",
    path: indexCb5OX093TAMeta?.path ?? "/mijn-aanvragen/:offerNumber()",
    meta: indexCb5OX093TAMeta || {},
    alias: indexCb5OX093TAMeta?.alias || [],
    redirect: indexCb5OX093TAMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-aanvragen/[offerNumber]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjak0tm7000Meta?.name ?? "mijn-aanvragen",
    path: indexjak0tm7000Meta?.path ?? "/mijn-aanvragen",
    meta: indexjak0tm7000Meta || {},
    alias: indexjak0tm7000Meta?.alias || [],
    redirect: indexjak0tm7000Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-aanvragen/index.vue").then(m => m.default || m)
  },
  {
    name: indexUA4NNRlV10Meta?.name ?? "mijn-organisaties-companyId-iban-overzicht",
    path: indexUA4NNRlV10Meta?.path ?? "/mijn-organisaties/:companyId()/iban-overzicht",
    meta: indexUA4NNRlV10Meta || {},
    alias: indexUA4NNRlV10Meta?.alias || [],
    redirect: indexUA4NNRlV10Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-organisaties/[companyId]/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexWtqHWMObyOMeta?.name ?? "mijn-organisaties-bedrijfs-gegevens",
    path: indexWtqHWMObyOMeta?.path ?? "/mijn-organisaties/bedrijfs-gegevens",
    meta: indexWtqHWMObyOMeta || {},
    alias: indexWtqHWMObyOMeta?.alias || [],
    redirect: indexWtqHWMObyOMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-organisaties/bedrijfs-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexPDkezw0smpMeta?.name ?? "mijn-organisaties",
    path: indexPDkezw0smpMeta?.path ?? "/mijn-organisaties",
    meta: indexPDkezw0smpMeta || {},
    alias: indexPDkezw0smpMeta?.alias || [],
    redirect: indexPDkezw0smpMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-organisaties/index.vue").then(m => m.default || m)
  },
  {
    name: indexECiILnXeSdMeta?.name ?? "mijn-partnerplan-token-choice",
    path: indexECiILnXeSdMeta?.path ?? "/mijn-partnerplan/:token()/:choice()",
    meta: indexECiILnXeSdMeta || {},
    alias: indexECiILnXeSdMeta?.alias || [],
    redirect: indexECiILnXeSdMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-partnerplan/[token]/[choice]/index.vue").then(m => m.default || m)
  },
  {
    name: indexW9q59FYmREMeta?.name ?? "mijn-partnerplan",
    path: indexW9q59FYmREMeta?.path ?? "/mijn-partnerplan",
    meta: indexW9q59FYmREMeta || {},
    alias: indexW9q59FYmREMeta?.alias || [],
    redirect: indexW9q59FYmREMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-partnerplan/index.vue").then(m => m.default || m)
  },
  {
    name: indexEHcuYBTdCKMeta?.name ?? "mijn-profiel-digitaal-identificeren",
    path: indexEHcuYBTdCKMeta?.path ?? "/mijn-profiel/digitaal-identificeren",
    meta: indexEHcuYBTdCKMeta || {},
    alias: indexEHcuYBTdCKMeta?.alias || [],
    redirect: indexEHcuYBTdCKMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-profiel/digitaal-identificeren/index.vue").then(m => m.default || m)
  },
  {
    name: indexj5xadAnqCmMeta?.name ?? "mijn-profiel-iban-overzicht",
    path: indexj5xadAnqCmMeta?.path ?? "/mijn-profiel/iban-overzicht",
    meta: indexj5xadAnqCmMeta || {},
    alias: indexj5xadAnqCmMeta?.alias || [],
    redirect: indexj5xadAnqCmMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-profiel/iban-overzicht/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9noxlmL8SMeta?.name ?? "mijn-profiel",
    path: indexe9noxlmL8SMeta?.path ?? "/mijn-profiel",
    meta: indexe9noxlmL8SMeta || {},
    alias: indexe9noxlmL8SMeta?.alias || [],
    redirect: indexe9noxlmL8SMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-profiel/index.vue").then(m => m.default || m)
  },
  {
    name: indexiKHnIV4QeNMeta?.name ?? "mijn-profiel-naw-gegevens",
    path: indexiKHnIV4QeNMeta?.path ?? "/mijn-profiel/naw-gegevens",
    meta: indexiKHnIV4QeNMeta || {},
    alias: indexiKHnIV4QeNMeta?.alias || [],
    redirect: indexiKHnIV4QeNMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mijn-profiel/naw-gegevens/index.vue").then(m => m.default || m)
  },
  {
    name: indexlRQPcayrasMeta?.name ?? "mogelijk-fonds-id-deelname",
    path: indexlRQPcayrasMeta?.path ?? "/mogelijk-fonds/:id()/deelname",
    meta: indexlRQPcayrasMeta || {},
    alias: indexlRQPcayrasMeta?.alias || [],
    redirect: indexlRQPcayrasMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/deelname/index.vue").then(m => m.default || m)
  },
  {
    name: indexno17tGw3orMeta?.name ?? "mogelijk-fonds-id-documenten",
    path: indexno17tGw3orMeta?.path ?? "/mogelijk-fonds/:id()/documenten",
    meta: indexno17tGw3orMeta || {},
    alias: indexno17tGw3orMeta?.alias || [],
    redirect: indexno17tGw3orMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/documenten/index.vue").then(m => m.default || m)
  },
  {
    name: index3IzTQSOJJrMeta?.name ?? "mogelijk-fonds-id",
    path: index3IzTQSOJJrMeta?.path ?? "/mogelijk-fonds/:id()",
    meta: index3IzTQSOJJrMeta || {},
    alias: index3IzTQSOJJrMeta?.alias || [],
    redirect: index3IzTQSOJJrMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfq8jfJB4ZbMeta?.name ?? "mogelijk-fonds-id-inschrijving",
    path: indexfq8jfJB4ZbMeta?.path ?? "/mogelijk-fonds/:id()/inschrijving",
    meta: indexfq8jfJB4ZbMeta || {},
    alias: indexfq8jfJB4ZbMeta?.alias || [],
    redirect: indexfq8jfJB4ZbMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/inschrijving/index.vue").then(m => m.default || m)
  },
  {
    name: indexCKK9dCoPYRMeta?.name ?? "mogelijk-fonds-id-ondertekenen",
    path: indexCKK9dCoPYRMeta?.path ?? "/mogelijk-fonds/:id()/ondertekenen",
    meta: indexCKK9dCoPYRMeta || {},
    alias: indexCKK9dCoPYRMeta?.alias || [],
    redirect: indexCKK9dCoPYRMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/ondertekenen/index.vue").then(m => m.default || m)
  },
  {
    name: indexnHFp37evdJMeta?.name ?? "mogelijk-fonds-id-overmaken",
    path: indexnHFp37evdJMeta?.path ?? "/mogelijk-fonds/:id()/overmaken",
    meta: indexnHFp37evdJMeta || {},
    alias: indexnHFp37evdJMeta?.alias || [],
    redirect: indexnHFp37evdJMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/[id]/overmaken/index.vue").then(m => m.default || m)
  },
  {
    name: indexBUQBxLD4L8Meta?.name ?? "mogelijk-fonds",
    path: indexBUQBxLD4L8Meta?.path ?? "/mogelijk-fonds",
    meta: indexBUQBxLD4L8Meta || {},
    alias: indexBUQBxLD4L8Meta?.alias || [],
    redirect: indexBUQBxLD4L8Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/mogelijk-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: errorSQzNblMTkoMeta?.name ?? "ockto-hash-error",
    path: errorSQzNblMTkoMeta?.path ?? "/ockto/:hash()/error",
    meta: errorSQzNblMTkoMeta || {},
    alias: errorSQzNblMTkoMeta?.alias || [],
    redirect: errorSQzNblMTkoMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/ockto/[hash]/error.vue").then(m => m.default || m)
  },
  {
    name: indexO2hvbG81GHMeta?.name ?? "ockto-hash",
    path: indexO2hvbG81GHMeta?.path ?? "/ockto/:hash()",
    meta: indexO2hvbG81GHMeta || {},
    alias: indexO2hvbG81GHMeta?.alias || [],
    redirect: indexO2hvbG81GHMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/ockto/[hash]/index.vue").then(m => m.default || m)
  },
  {
    name: qrcodeqNmLaoBLa3Meta?.name ?? "ockto-hash-qrcode",
    path: qrcodeqNmLaoBLa3Meta?.path ?? "/ockto/:hash()/qrcode",
    meta: qrcodeqNmLaoBLa3Meta || {},
    alias: qrcodeqNmLaoBLa3Meta?.alias || [],
    redirect: qrcodeqNmLaoBLa3Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/ockto/[hash]/qrcode.vue").then(m => m.default || m)
  },
  {
    name: succes6ogie0VjLXMeta?.name ?? "ockto-hash-succes",
    path: succes6ogie0VjLXMeta?.path ?? "/ockto/:hash()/succes",
    meta: succes6ogie0VjLXMeta || {},
    alias: succes6ogie0VjLXMeta?.alias || [],
    redirect: succes6ogie0VjLXMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/ockto/[hash]/succes.vue").then(m => m.default || m)
  },
  {
    name: _91token_93nkuM9iwfBPMeta?.name ?? "onboarding-signing_id-token",
    path: _91token_93nkuM9iwfBPMeta?.path ?? "/onboarding/:signing_id()/:token()",
    meta: _91token_93nkuM9iwfBPMeta || {},
    alias: _91token_93nkuM9iwfBPMeta?.alias || [],
    redirect: _91token_93nkuM9iwfBPMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/onboarding/[signing_id]/[token].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93B6iUIK5QxgMeta?.name ?? "onboarding-slug",
    path: _91slug_93B6iUIK5QxgMeta?.path ?? "/onboarding/:slug()",
    meta: _91slug_93B6iUIK5QxgMeta || {},
    alias: _91slug_93B6iUIK5QxgMeta?.alias || [],
    redirect: _91slug_93B6iUIK5QxgMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/onboarding/[slug].vue").then(m => m.default || m)
  },
  {
    name: aanvraagPipNRKuXhxMeta?.name ?? "onboarding-aanvraag",
    path: aanvraagPipNRKuXhxMeta?.path ?? "/onboarding/aanvraag",
    meta: aanvraagPipNRKuXhxMeta || {},
    alias: aanvraagPipNRKuXhxMeta?.alias || [],
    redirect: aanvraagPipNRKuXhxMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: _91experience_93PFvEN1m5msMeta?.name ?? "onboarding-bedankt-experience",
    path: _91experience_93PFvEN1m5msMeta?.path ?? "/onboarding/bedankt/:experience()",
    meta: _91experience_93PFvEN1m5msMeta || {},
    alias: _91experience_93PFvEN1m5msMeta?.alias || [],
    redirect: _91experience_93PFvEN1m5msMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/onboarding/bedankt/[experience].vue").then(m => m.default || m)
  },
  {
    name: _91token_9386KSUsEyDAMeta?.name ?? "onfido-token",
    path: _91token_9386KSUsEyDAMeta?.path ?? "/onfido/:token()",
    meta: _91token_9386KSUsEyDAMeta || {},
    alias: _91token_9386KSUsEyDAMeta?.alias || [],
    redirect: _91token_9386KSUsEyDAMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/onfido/[token].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93qj8enUFlY4Meta?.name ?? "passeren-hash",
    path: _91hash_93qj8enUFlY4Meta?.path ?? "/passeren/:hash()",
    meta: _91hash_93qj8enUFlY4Meta || {},
    alias: _91hash_93qj8enUFlY4Meta?.alias || [],
    redirect: _91hash_93qj8enUFlY4Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/passeren/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexIqCEmI6DNhMeta?.name ?? "privacy-verklaring",
    path: indexIqCEmI6DNhMeta?.path ?? "/privacy-verklaring",
    meta: indexIqCEmI6DNhMeta || {},
    alias: indexIqCEmI6DNhMeta?.alias || [],
    redirect: indexIqCEmI6DNhMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/privacy-verklaring/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_936jhiMqqTXQMeta?.name ?? "rentevoorstel-hash",
    path: _91hash_936jhiMqqTXQMeta?.path ?? "/rentevoorstel/:hash()",
    meta: _91hash_936jhiMqqTXQMeta || {},
    alias: _91hash_936jhiMqqTXQMeta?.alias || [],
    redirect: _91hash_936jhiMqqTXQMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/rentevoorstel/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91fundKey_93C5ZSHJeP4wMeta?.name ?? "sso-exchangeToken-fundKey",
    path: _91fundKey_93C5ZSHJeP4wMeta?.path ?? "/sso/:exchangeToken()/:fundKey()",
    meta: _91fundKey_93C5ZSHJeP4wMeta || {},
    alias: _91fundKey_93C5ZSHJeP4wMeta?.alias || [],
    redirect: _91fundKey_93C5ZSHJeP4wMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/sso/[exchangeToken]/[fundKey].vue").then(m => m.default || m)
  },
  {
    name: aanvraagpAZijb82H5Meta?.name ?? "sso-exchangeToken-onboarding-aanvraag",
    path: aanvraagpAZijb82H5Meta?.path ?? "/sso/:exchangeToken()/onboarding/aanvraag",
    meta: aanvraagpAZijb82H5Meta || {},
    alias: aanvraagpAZijb82H5Meta?.alias || [],
    redirect: aanvraagpAZijb82H5Meta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/sso/[exchangeToken]/onboarding/aanvraag.vue").then(m => m.default || m)
  },
  {
    name: index3hUigKM9cdMeta?.name ?? "sso-exchangeToken-onboarding",
    path: index3hUigKM9cdMeta?.path ?? "/sso/:exchangeToken()/onboarding",
    meta: index3hUigKM9cdMeta || {},
    alias: index3hUigKM9cdMeta?.alias || [],
    redirect: index3hUigKM9cdMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/sso/[exchangeToken]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931aL2N7hmMuMeta?.name ?? "sso-token-id",
    path: _91id_931aL2N7hmMuMeta?.path ?? "/sso/:token()/:id()",
    meta: _91id_931aL2N7hmMuMeta || {},
    alias: _91id_931aL2N7hmMuMeta?.alias || [],
    redirect: _91id_931aL2N7hmMuMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/sso/[token]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexH8F6VcmkETMeta?.name ?? "sso-token",
    path: indexH8F6VcmkETMeta?.path ?? "/sso/:token()",
    meta: indexH8F6VcmkETMeta || {},
    alias: indexH8F6VcmkETMeta?.alias || [],
    redirect: indexH8F6VcmkETMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/sso/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93iKbK9Y7o2WMeta?.name ?? "verzekeringen-hash",
    path: _91hash_93iKbK9Y7o2WMeta?.path ?? "/verzekeringen/:hash()",
    meta: _91hash_93iKbK9Y7o2WMeta || {},
    alias: _91hash_93iKbK9Y7o2WMeta?.alias || [],
    redirect: _91hash_93iKbK9Y7o2WMeta?.redirect || undefined,
    component: () => import("/shared/home/frontend/deployr/releases/20250409072316/packages/investors/pages/verzekeringen/[hash].vue").then(m => m.default || m)
  }
]